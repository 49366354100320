import {makeStyles} from "@material-ui/styles";
import * as React from 'react';
import {IconButton, Badge} from '@material-ui/core'
import {Notifications as NotificationsIcon, Settings as SettingsIcon, ExitToApp as ExitIcon} from '@material-ui/icons'
import {useSubscription} from '@apollo/react-hooks'
import {Link, navigate} from 'gatsby'
import {unsetLocalToken} from '../../utils/client'
import {confirmationDialog} from '../../utils/helpers'
import {useNotificationCountQuery, NotificationCountDocument} from '../../apollo/generated';

const useStyles = makeStyles({
  icon: {
    color: '#fff'
  }
});

const OptionIcons = () => {
  const classes = useStyles();
  const user = useNotificationCountQuery()?.data?.user;
  useSubscription(NotificationCountDocument, {
    // eslint-disable-next-line no-empty-pattern
    onSubscriptionData: ({ }) => {
    }
  })
  const notificationCount = user ? user.notificationCount : 0;
  return (
    <>
      <Link to={"/notifications"}>
        <IconButton className={classes.icon}>
          <Badge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon/>
          </Badge>
        </IconButton>
      </Link>
      <IconButton className={classes.icon} onClick={() => navigate('/settings')}>
        <SettingsIcon/>
      </IconButton>
      <IconButton
        className={classes.icon}
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onClick={tryLogout}
      >
        <ExitIcon/>
      </IconButton>
    </>
  )
}

async function tryLogout() {
  const confirmed = await confirmationDialog('Sign Out', 'Sign Out', 'Are you sure you want to end your current session?')
  if (confirmed) {
    unsetLocalToken();
    navigate('/');
    setTimeout(() => location.reload(), 0)
    // TODO: Remove the user ID from Apollo cache.
  }
}

export default OptionIcons
